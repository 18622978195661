import { createContext, useContext, useState, useEffect, useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'
import { useScrollLock } from '~/lib/scrollLock'
import { useScrollBehavior } from '~/lib/hooks/useScrollBehavior'
import { useAutocomplete } from '~/lib/Context/AutocompleteProvider'
import { ColorTheme } from '~/lib/constants/themeConstants'
import { ActiveLevel } from '~/lib/constants/activeLevelConstants'

type NavigationContextType = {
  activeLevel: ActiveLevel
  activeLevelIndex: number
  isSearchFlyoutOpen: boolean
  isMobileFlyoutOpen: boolean
  isDesktopFlyoutOpen: boolean
  displaySearchInput: boolean
  lastScrollY: number
  navigationTheme: ColorTheme
  displayLightTheme: boolean
  isFlyoutOpen: boolean
  isHeaderSticky: boolean
  isHeaderHidden: boolean
  toggleDesktopFlyout: (level: ActiveLevel, index: number) => void
  toggleSearch: (isOpen: boolean) => void
  toggleMobileFlyout: (isOpen: boolean) => void
  closeHeaderFlyout: () => void
  handleSearchFocus: () => void
  handleSearchClose: () => void
  handleSearchBlur: () => void
  setNavigationTheme: (theme: ColorTheme) => void
  setHeaderHeight: (height: number) => void
  handleDisplaySearchInput: (display: boolean) => void
}

const NavigationContext = createContext<NavigationContextType | undefined>(undefined)

export const NavigationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { searchSuggestions, products, searchValue } = useAutocomplete()
  const router = useRouter()

  const [flyoutState, setFlyoutState] = useState({
    activeLevel: null as ActiveLevel,
    activeLevelIndex: -1,
    isSearchFlyoutOpen: false,
    displaySearchInput: false,
    isMobileFlyoutOpen: false,
    isDesktopFlyoutOpen: false,
  })

  const [headerState, setHeaderState] = useState({
    headerHeight: 0,
    navigationTheme: 'dark' as ColorTheme,
  })

  const { lockScroll, unlockScroll } = useScrollLock()

  const { lastScrollY, isHeaderSticky, isScrollingUp, windowHeight } = useScrollBehavior({
    headerHeight: headerState.headerHeight,
    isSearchFlyoutOpen: flyoutState.isSearchFlyoutOpen,
  })

  const displaySearchInput = useMemo(() => {
    return !isHeaderSticky || flyoutState.isSearchFlyoutOpen || flyoutState.displaySearchInput
  }, [isHeaderSticky, flyoutState.isSearchFlyoutOpen, flyoutState.displaySearchInput])

  useEffect(() => {
    const shouldBeVisible =
      flyoutState.isSearchFlyoutOpen ||
      (flyoutState.activeLevel === 'first' && flyoutState.activeLevelIndex !== -1)

    setFlyoutState((prev) => ({ ...prev, isDesktopFlyoutOpen: shouldBeVisible }))
  }, [flyoutState.isSearchFlyoutOpen, flyoutState.activeLevel, flyoutState.activeLevelIndex])

  const isFlyoutOpen =
    flyoutState.isSearchFlyoutOpen ||
    flyoutState.isMobileFlyoutOpen ||
    flyoutState.isDesktopFlyoutOpen

  const [forceShowHeader, setForceShowHeader] = useState(false)

  const isHeaderHidden = useMemo(() => {
    if (forceShowHeader || isFlyoutOpen) return false

    return lastScrollY > windowHeight && !isScrollingUp
  }, [forceShowHeader, isFlyoutOpen, lastScrollY, windowHeight, isScrollingUp])

  useEffect(() => {
    if (lastScrollY > 0 && !isScrollingUp) {
      setForceShowHeader(false)
    }
  }, [isScrollingUp, lastScrollY])

  const displayLightTheme =
    headerState.navigationTheme.toLowerCase() === 'light' &&
    flyoutState.activeLevel !== 'first' &&
    !isHeaderSticky &&
    !(flyoutState.isSearchFlyoutOpen && searchValue.trim().length > 0)

  const handleSearchClose = useCallback(() => {
    setForceShowHeader(true)
    setFlyoutState((prev) => ({ ...prev, isSearchFlyoutOpen: false, displaySearchInput: false }))
  }, [])

  const toggleDesktopFlyout = useCallback(
    (level: ActiveLevel, index: number) => {
      if (index !== -1) {
        handleSearchClose()
      }

      setFlyoutState((prev) => ({ ...prev, activeLevel: level, activeLevelIndex: index }))
    },
    [handleSearchClose],
  )

  const toggleSearch = useCallback((isOpen: boolean) => {
    setFlyoutState((prev) => ({ ...prev, isSearchFlyoutOpen: isOpen }))
  }, [])

  const toggleMobileFlyout = useCallback((isOpen: boolean) => {
    setFlyoutState((prev) => ({ ...prev, isMobileFlyoutOpen: isOpen }))
  }, [])

  const closeHeaderFlyout = useCallback(() => {
    toggleDesktopFlyout(null, -1)
    handleSearchClose()
  }, [toggleDesktopFlyout, handleSearchClose])

  useEffect(() => {
    const shouldOpenFlyout =
      searchValue.trim().length > 0 && (searchSuggestions.length > 0 || products.length > 0)
    setFlyoutState((prev) => ({ ...prev, isSearchFlyoutOpen: shouldOpenFlyout }))
  }, [searchSuggestions.length, products.length, searchValue])

  const handleSearchFocus = useCallback(() => {
    if (searchValue.length > 0) {
      setFlyoutState((prev) => ({
        ...prev,
        isSearchFlyoutOpen: true,
        displaySearchInput: true,
        activeLevel: null,
        activeLevelIndex: -1,
      }))
    }
  }, [searchValue])

  const handleSearchBlur = useCallback(() => {
    handleSearchClose()
  }, [handleSearchClose])

  const setNavigationTheme = useCallback((theme: ColorTheme) => {
    setHeaderState((prev) => ({ ...prev, navigationTheme: theme }))
  }, [])

  const setHeaderHeight = useCallback((height: number) => {
    setHeaderState((prev) => ({ ...prev, headerHeight: height }))
  }, [])

  const handleDisplaySearchInput = useCallback((display: boolean) => {
    setFlyoutState((prev) => ({ ...prev, displaySearchInput: display }))
  }, [])

  const handleRouteChange = useCallback(() => {
    closeHeaderFlyout()
    toggleMobileFlyout(false)
  }, [closeHeaderFlyout, toggleMobileFlyout])

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router.events, handleRouteChange])

  useEffect(() => {
    if (!isScrollingUp && lastScrollY > 0) {
      handleDisplaySearchInput(false)
    }
  }, [isScrollingUp, lastScrollY, handleDisplaySearchInput])

  const value: NavigationContextType = {
    ...flyoutState,
    ...headerState,
    displaySearchInput,
    handleDisplaySearchInput,
    lastScrollY,
    displayLightTheme,
    isFlyoutOpen,
    isHeaderSticky,
    isHeaderHidden,
    toggleDesktopFlyout,
    toggleSearch,
    toggleMobileFlyout,
    closeHeaderFlyout,
    handleSearchFocus,
    handleSearchClose,
    handleSearchBlur,
    setNavigationTheme,
    setHeaderHeight,
  }

  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>
}

export const useNavigation = () => {
  const context = useContext(NavigationContext)

  if (context === undefined) {
    throw new Error('useNavigation must be used within a NavigationProvider')
  }

  return context
}
