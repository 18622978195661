import { useEffect, useRef, useCallback } from 'react'

const getScrollbarWidth = (): number => {
  return window.innerWidth - document.documentElement.clientWidth
}

export const useScrollLock = () => {
  const scrollbarWidth = useRef<number>(0)
  const originalStyles = useRef({
    overflow: '',
    width: '',
  })
  const isLocked = useRef(false)

  const lockScroll = useCallback(() => {
    if (!isLocked.current) {
      scrollbarWidth.current = getScrollbarWidth()
      originalStyles.current = {
        overflow: document.body.style.overflow,
        width: document.body.style.width,
      }

      document.body.style.overflow = 'hidden'
      isLocked.current = true
    }
  }, [])

  const unlockScroll = useCallback(() => {
    if (isLocked.current) {
      document.body.style.overflow = originalStyles.current.overflow
      document.body.style.width = originalStyles.current.width
      isLocked.current = false
    }
  }, [])

  useEffect(() => {
    return () => {
      unlockScroll()
    }
  }, [unlockScroll])

  return { lockScroll, unlockScroll, isLocked: isLocked, scrollbarWidth }
}
