import { createContext, useContext, useState, useEffect, useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'
import { useCioClient } from '~/hooks/useCioClient'
import debounce from 'lodash.debounce'
import { Item } from '@constructor-io/constructorio-client-javascript'

type AutocompleteContextType = {
  searchValue: string
  setQuery: (value: string) => void
  products: Item[]
  searchSuggestions: Item[]
  loading: boolean
}

const AutocompleteContext = createContext<AutocompleteContextType | undefined>(undefined)

export const AutocompleteProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const cioClient = useCioClient()
  const [searchValue, setSearchValue] = useState('')
  const [products, setProducts] = useState<Item[]>([])
  const [searchSuggestions, setSearchSuggestions] = useState<Item[]>([])
  const [loading, setLoading] = useState(true)

  const router = useRouter()
  const { q } = router.query

  useEffect(() => {
    if (q) {
      setSearchValue('')
    }
  }, [router.asPath, q])

  const debouncedSearch = useCallback(
    (query: string) => {
      if (query.trim().length > 0) {
        cioClient?.autocomplete
          .getAutocompleteResults(query, {
            resultsPerSection: {
              Products: 6,
              'Search Suggestions': 6,
            },
          })
          .then((result) => {
            setProducts(result?.sections?.Products ?? [])
            setSearchSuggestions(result?.sections?.['Search Suggestions'] ?? [])
            setLoading(false)
          })
          .catch(() => {
            setProducts([])
            setSearchSuggestions([])
            setLoading(false)
          })
      } else {
        setProducts([])
        setSearchSuggestions([])
        setLoading(false)
      }
    },
    [cioClient, setLoading, setProducts, setSearchSuggestions],
  )

  const getSearchData = useMemo(() => debounce(debouncedSearch, 300), [debouncedSearch])

  const setQuery = useCallback(
    (query: string = '') => {
      setLoading(true)
      setSearchValue(query)
      getSearchData(query)
    },
    [getSearchData, setLoading, setSearchValue],
  )

  const value = {
    searchValue,
    setQuery,
    products,
    searchSuggestions,
    loading,
  }

  return <AutocompleteContext.Provider value={value}>{children}</AutocompleteContext.Provider>
}

export const useAutocomplete = () => {
  const context = useContext(AutocompleteContext)

  if (context === undefined) {
    throw new Error('useAutocomplete must be used within a AutocompleteProvider')
  }

  return context
}
