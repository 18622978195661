import { useEffect, useMemo, useState } from 'react'
import ConstructorIOClient, {
  ConstructorClientOptions,
} from '@constructor-io/constructorio-client-javascript'
import { useRouter } from 'next/router'
import { DEFAULT_LOCALE } from '~/config/constants'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { useSession } from '~/hooks/useSession'
import { getCioUserId } from '~/lib/cioHelpers'

const resolveApiKey = (locale: string): string | undefined => {
  if (process.env.NEXT_PUBLIC_CONSTRUCTORIO_API_KEYS) {
    const keys = JSON.parse(process.env.NEXT_PUBLIC_CONSTRUCTORIO_API_KEYS)

    return keys?.[locale]
  }

  return undefined
}

export const useCioClient = () => {
  const [session] = useSession()
  const router = useRouter()
  const locale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const [apiKey, setApiKey] = useState<string | undefined>(resolveApiKey(locale))
  const [cioClient, setCioClient] = useState<ConstructorIOClient | null>(null)

  const clientOptions = useMemo<ConstructorClientOptions>(() => {
    if (!apiKey) {
      return {} as ConstructorClientOptions
    }

    return {
      apiKey,
      userId: getCioUserId(session?.user?.email),
    }
  }, [apiKey, session?.user?.email])

  useEffect(() => {
    if (cioClient) {
      cioClient.setClientOptions(clientOptions)
    }
  }, [cioClient, clientOptions])

  useEffect(() => {
    try {
      const newApiKey = resolveApiKey(locale)
      setApiKey(newApiKey)

      if (!newApiKey) {
        setCioClient(null)
        return
      }

      const client = new ConstructorIOClient({ apiKey: newApiKey })
      setCioClient(client)
    } catch (err) {
      console.warn('Unable to init cioClient')
    }
  }, [locale])

  return cioClient
}
