import { useState, useEffect, useRef } from 'react'
import throttle from 'lodash.throttle'

interface ScrollBehaviorProps {
  headerHeight: number
  isSearchFlyoutOpen: boolean
}

export const useScrollBehavior = ({ headerHeight, isSearchFlyoutOpen }: ScrollBehaviorProps) => {
  const [lastScrollY, setLastScrollY] = useState(0)
  const [isHeaderSticky, setIsSticky] = useState(false)
  const [windowHeight, setWindowHeight] = useState(0)
  const [isScrollingUp, setIsScrollingUp] = useState(false)
  const prevScrollY = useRef(lastScrollY)

  useEffect(() => {
    const updateWindowHeight = () => {
      setWindowHeight(window.innerHeight)
    }

    updateWindowHeight()

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === document.documentElement) {
          updateWindowHeight()
          return
        }
      }
    })

    resizeObserver.observe(document.documentElement)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  useEffect(() => {
    const handleScroll = throttle(() => {
      const currentScrollY = window.scrollY
      const isHeaderSticky = currentScrollY > headerHeight
      const isScrollingUpward = currentScrollY < prevScrollY.current
      prevScrollY.current = currentScrollY

      setIsSticky(isHeaderSticky)
      setIsScrollingUp(isScrollingUpward)
      setLastScrollY(currentScrollY)
    }, 200)

    handleScroll()

    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      handleScroll.cancel()
      window.removeEventListener('scroll', handleScroll)
    }
  }, [headerHeight, isSearchFlyoutOpen])

  return {
    lastScrollY,
    isHeaderSticky,
    windowHeight,
    isScrollingUp,
    prevScrollY,
  }
}
