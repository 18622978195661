import i18nconfig from '../next-i18next.config.js'

export const TOKEN = 'auth-token'
export const MAGENTO_ENDPOINT = `${process.env.NEXT_PUBLIC_MAGENTO_URL}`
export const EMAIL_PATTERN =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

export const NAME_REGEX = /^[\p{L} ,.'-]+$/u //will also match special letters like umlaute, spaces and characters used in names like .,-'
export const TELEPHONE_PATTERN = /^[0-9\-,+\s]+$/i
export const MINIMUM_ONE_NUMBER = /^(?=.{6,100}$)\D*\d/
export const PAGE_SIZE = 48
export const VALUE_SEPARATOR = ','
export const FILTER_SEPARATOR = '|'
export const KEY_SEPARATOR = ':'
export const CART_ID = 'cartID'
export const DEFAULT_LOCALE = i18nconfig.i18n.defaultLocale
export const CHECKOUT_FORM_STEP_ONE = 'checkout-form-step-1'
export const possibleStores = [
  {
    land: 'DE',
    lang: 'DE',
    url: 'https://www.benuta.de/',
    name: 'Germany',
  },
  {
    land: 'AT',
    lang: 'DE',
    url: 'https://www.benuta.at/',
    name: 'Austria',
  },
  {
    land: 'GB',
    lang: 'EN',
    url: 'https://www.benuta.co.uk/',
    name: 'United Kingdom',
  },
  {
    land: 'EU',
    lang: 'EN',
    url: 'https://www.benuta.eu/',
    name: 'European Union',
  },
  {
    land: 'IT',
    lang: 'IT',
    url: 'https://www.benuta.it/',
    name: 'Italy',
  },
  {
    land: 'SE',
    lang: 'SV',
    url: 'https://www.benuta.se/',
    name: 'Sweden',
  },
  {
    land: 'ES',
    lang: 'ES',
    url: 'https://www.benuta.es/',
    name: 'Spain',
  },
  {
    land: 'DK',
    lang: 'DA',
    url: 'https://www.benuta.dk/',
    name: 'Denmark',
  },
  {
    land: 'NL',
    lang: 'NL',
    url: 'https://www.benuta.nl/',
    name: 'Netherlands',
  },
  {
    land: 'FR',
    lang: 'FR',
    url: 'https://www.benuta.fr/',
    name: 'France',
  },
  {
    land: 'PL',
    lang: 'PL',
    url: 'https://www.benuta.pl/',
    name: 'Poland',
  },
  {
    land: 'CH',
    lang: 'DE',
    url: 'https://www.benuta.ch/de/',
    name: 'Switzerland',
  },
  {
    land: 'CH',
    lang: 'IT',
    url: 'https://www.benuta.ch/it/',
    name: 'Switzerland',
  },
  {
    land: 'CH',
    lang: 'FR',
    name: 'Switzerland',
    url: 'https://www.benuta.ch/fr/',
  },
  {
    land: 'CZ',
    lang: 'CS',
    url: 'https://www.benuta.cz/',
    name: 'Czech Republic',
  },
  {
    land: 'PT',
    lang: 'PT',
    url: 'https://www.benuta.pt/',
    name: 'Portugal',
  },
  {
    land: 'FI',
    lang: 'FI',
    url: 'https://www.benuta.fi/',
    name: 'Finland',
  },
  {
    land: 'NO',
    lang: 'NO',
    url: 'https://www.benuta.no/',
    name: 'Norway',
  },
] as const

export type Store = typeof possibleStores[number]

export const SAVED_AUTH_TOKEN = 'benuta_auth_token'
export const SAVED_CART = 'benuta_saved_cart'
export const SAVED_TRUSTPILOT_CONSENT = 'benuta_tp_consent'
